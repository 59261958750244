import { SyncLogQueryModel } from '@common-src/entity-model/sync-log.entity';
import { ModuleType } from '@common-src/enum/module-type.enum';
import { IResponseFilterData } from '@common-src/interface/reponse-data.interface';
import http from '@common-src/service3/http/axios';

export async function getAllSyncLog(filter?: SyncLogQueryModel, page?: number, pageSize?: number):Promise<IResponseFilterData<any>> {
    const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
    const res = await http.request<IResponseFilterData<any>>({
        url: `${IOT_BASE_REQUEST_PATH}/log/sync/list`,
        method: 'POST',
        params
    });
    return res;
}

/**
 * @description: 获取所有事件类型
 */
export async function getAllEventType(): Promise<Array<any>> {
    const res = await http.request<Array<OptionItem>>({
        url: `${IOT_BASE_REQUEST_PATH}/log/sync/entityTypes`,
        method: 'POST'
    });
    return _.map(res, item => ({
        name: item.key,
        value: item.value
    }));
}

/**
 * @description: 获取所有执行动作类型
 */
export async function getAllActionType(): Promise<Array<any>> {
    const res = await http.request<Array<OptionItem>>({
        url: `${IOT_BASE_REQUEST_PATH}/log/sync/actionTypes`,
        method: 'POST'
    });
    return _.map(res, item => ({
        name: item.key,
        value: item.value
    }));
}
