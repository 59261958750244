import { BaseModel, BaseEntityModel } from '@common-src/model/base-model';
import { DeviceTypeEnum, DeviceStatus, GroupEntityType, TemplateType, ProtocolType } from '@common-src/model/enum';
import { FormControl, FormControlType, FormControlModel, FormControlTextModel, FormControlOptionModel, FormControlUploadModel } from '@common-src/model/form-control';
import CommonService from '@common-src/service/common';
import { ThingsTemplateQueryModel } from './things-template-entity';
import { ThingsFunctionGroupModel } from './things-function-entity';
import { dateFormat, dateFromNow } from '@common-src/filter';
import { generateUUID } from '@common-src/utils/base_util';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { deviceStatusList } from '@common-src/filter/device-status';
import { protocolTypeList } from '@common-src/filter/protocol-type';
import { getBizTypes, getAllEdge } from '@common-src/service3/common.service';
import { ModuleType } from '@common-src/enum/module-type.enum';
import { QueryPageModel } from '@common-src/model/query-model';
import { getAllEventType, getAllActionType } from '@common-src/service3/sync-log.service';
import { Moment } from 'moment';

export class SyncLogEntityModel extends ThingsFunctionGroupModel {
    static getTableColumns() {
        const tableColumns = [
            {
                title: '事件类型',
                dataIndex: 'entityType',
                width: 150,
                ellipsis: true
            },
            {
                title: '执行动作',
                dataIndex: 'actionType',
                width: 150,
                ellipsis: true
            },
            {
                title: '实体对象',
                dataIndex: 'entityName',
                ellipsis: true
            },
            {
                title: '边缘实例',
                dataIndex: 'edgeName',
                width: 120,
                ellipsis: true
            },
            {
                title: '同步内容',
                dataIndex: 'content',
                ellipsis: true,
                customRender: (text) => {
                    return JSON.stringify(text).slice(1, -1);
                }
            },
            {
                title: '错误日志',
                dataIndex: 'errorMessage',
                ellipsis: true
            },
            {
                title: '结果',
                dataIndex: 'result',
                customRender: (text) => {
                    if (_.isNil(text)) {
                        return '-';
                    }
                    return text ? '成功' : '失败';
                }
            },
            // {
            //     title: '创建人',
            //     dataIndex: 'createdPersion',
            //     width: 120,
            //     ellipsis: true
            // },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                customRender: (text, record, index) => {
                    if (text) {
                        return dateFormat(text);
                    }
                    return '-';
                },
                width: 250
            }
        ];
        if (JTL.moduleType === ModuleType.EDGE) {
            return _.filter(tableColumns, item => item.dataIndex !== 'edgeName');
        }
        return tableColumns;
    }
}

export class SyncLogQueryModel extends QueryPageModel {
    @QueryControl({
        label: '事件类型',
        type: QueryControlType.SELECT,
        optionsPromise: getAllEventType,
        hasAllOption: true,
        span: 6,
        index: 1
    })
    entityType: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '执行动作',
        type: QueryControlType.SELECT,
        optionsPromise: getAllActionType,
        hasAllOption: true,
        span: 6,
        index: 2
    })
    actionType: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '实体对象',
        type: QueryControlType.TEXT,
        span: 6,
        index: 4
    })
    entityName: string = undefined;

    @QueryControl({
        label: '边缘实例',
        type: QueryControlType.SELECT,
        optionsPromise: getAllEdge,
        hasAllOption: true,
        span: 6,
        index: 5,
        invisibleFunction: () => JTL.moduleType === ModuleType.EDGE
    })
    edgeId: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '结果',
        type: QueryControlType.SELECT,
        options: [
            { name: '成功', value: true },
            { name: '失败', value: false }
        ],
        hasAllOption: true,
        span: 6,
        index: 3
    })
    result: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '同步方向',
        type: QueryControlType.SELECT,
        options: [
            { name: '上行', value: 'UP' },
            { name: '下行', value: 'DOWN' }
        ],
        hasAllOption: true,
        span: 6,
        index: 3
    })
    syncType: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '创建时间',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        index: 15
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    toService() {
        const data: any = super.getParams();
        data.params.startTime = this.dateRange[0]?.startOf('day').toDate().getTime();
        data.params.endTime = this.getEndTime(this.dateRange[1]);
        if (this.result !== '') {
            data.params.result = this.result;
        }
        delete data.params.dateRange;
        return data;
    }
}
