































import { Component } from 'vue-property-decorator';
import Tableomponent from '@common-src/mixins/table-component';
import { SyncLogEntityModel, SyncLogQueryModel } from '@common-src/entity-model/sync-log.entity';
import { getAllSyncLog } from '@common-src/service3/sync-log.service';
import { UserStoreModule } from '@common-src/store/modules/user';

@Component
export default class SyncLogListComponent extends Tableomponent<SyncLogEntityModel, SyncLogQueryModel> {
    created() {
        this.initTable({
            listFunc: getAllSyncLog,
            queryModel: new SyncLogQueryModel(),
            tableColumns: SyncLogEntityModel.getTableColumns()
        });
        this.getList();
    }
}

