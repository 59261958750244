var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-list-component table-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("同步日志")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "a-card",
          { staticClass: "table-query-card", attrs: { title: "查询" } },
          [
            _c("jtl-table-query-component", {
              ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
              on: { search: _vm.searchClick },
            }),
          ],
          1
        ),
        _c(
          "a-card",
          { attrs: { title: "列表" } },
          [
            _c("a-table", {
              attrs: {
                loading: _vm.listLoading,
                columns: _vm.tableColumns,
                "data-source": _vm.listData,
                pagination: _vm.Pagination,
                scroll: _vm.tableScroll,
                "row-key": _vm.TableRowKey,
                size: _vm.TableSize,
              },
              on: { change: _vm.tableChange },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }